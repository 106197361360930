import bus from '@/spa/utils/bus';
import { get, merge, isEmpty, mapValues, isArray, isPlainObject, map, isString, camelCase, omit, isEqual, pick } from 'lodash';
import cloneDeep from 'rfdc/default';
import { totalsComputationInstance } from '@/spa/utils/computations';
import {
    USE_EXPERIMENTAL_STORAGE_STRATEGY,
    USE_DEXIE_STORAGE,
    BROADCAST_BUFFER_SECONDS,
    BROADCAST_COOLDOWN_SECONDS,
    ENABLE_BROADCAST_OPTIMIZATION,
    ENABLE_SALES_CONSOLIDATOR,
    ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE,
    OFFLOAD,
    ACTIVE_ACCOUNT_TYPE,
    ACCOUNT_TYPES,
} from '@/spa/constants';
import localForage from 'localforage';
import { trimSyncedOrder } from '@/spa/utils/object-trimmer';
import {
    broadcastOrderUpdate,
    getLatestOrders,
    storeSyncedOrder,
    forceSyncOrder,
    syncOrder,
    sqliteOffloadSync
} from '@/spa/services/sync-service';
import { safeLocalForageSet } from '@/spa/utils/local-storage';
import seriesService from '@/spa/services/series-service';
import { trackOrder, untrackOrder } from '@/spa/services/unsync-tracker-service';

const USE_OPTIMIZED_BROADCAST = ENABLE_BROADCAST_OPTIMIZATION && !window.isSharedTerminal;

import swal from 'sweetalert';
import {OFFLOAD_RECEIPT_ACTION} from "@/mobile_bridge/offload/offload-receipt";
import {sqliteOffloadBridges} from "@/mobile_bridge/offload/offload-bridges";
import {
    generateDateTime,
    posDateWithCurrentTime,
    RECEIPT_CONTENT_TYPE,
    TRANSACTION_STATUS_ID
} from "@/mobile_bridge/offload/receipt-model";
import {storeToS3} from "@/spa/services/logger-service";
import {isNetworkStable} from "@/spa/utils/networkCheck";
import commonUtil from "@/spa/utils/common-util";

const isInteger = num => /^-?[0-9]+$/.test(num + '');
const isNumeric = num => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num + '');

export function toNumbersDeep(obj) {
    if (isArray(obj)) return obj.map(toNumbersDeep);
    if (isPlainObject(obj)) return mapValues(obj, toNumbersDeep);
    if (!isString(obj)) return obj;
    if (isString(obj) && obj.length > 15) return obj;
    if (isString(obj) && obj.length > 1 && obj[0] === '0') return obj;
    if (isInteger(obj)) return parseInt(obj, 10);
    return isNumeric(obj) ? parseFloat(obj) : obj;
}

let timers = {};
let payloads = {};
let cooldownTracker = {};
let retryCounts = {};
function delayedBroadcastOrderUpdate(order) {
    // skip if sqliteOffloadReceipt is enabled
    if (OFFLOAD.sqliteOffloadReceipt) {
        return;
    }

    const orderId = String(order._id);

    if (cooldownTracker[orderId]) {
        setTimeout(() => delayedBroadcastOrderUpdate(order), 500);
        return;
    }

    if (timers[orderId]) {
        clearTimeout(timers[orderId]);
    }

    if (payloads[orderId]) {
        order['isFirstBroadcast'] = order['isFirstBroadcast'] || payloads[orderId]['isFirstBroadcast'];
        payloads[orderId] = merge(payloads[orderId], order);
    } else {
        payloads[orderId] = order;
    }

    timers[orderId] = setTimeout(async () => {
        cooldownTracker[orderId] = true;
        try {
            const response =  await broadcastOrderUpdate(payloads[orderId]);
            const { data } = response;

            if (data.isSanitized && data.order) {
                window.forceFetch = true;

                const lastBillNum = await seriesService.getLastBillNum();
                if (data.order.bill_num && lastBillNum < data.order.bill_num) {
                    seriesService.setLastBillNum(data.order.bill_num);
                }

                const lastReceiptNum = await seriesService.getLastReceiptNum();
                if (data.order.receipt_num && lastReceiptNum < data.order.receipt_num) {
                    seriesService.setLastReceiptNum(data.order.receipt_num);
                }
            }

            checkIfHasWarnings(response);
            untrackOrder(orderId);
        } catch {
            if (retryCounts[orderId] === undefined) {
                retryCounts[orderId] = 0;
            }

            if (retryCounts[orderId] <= 10) {
                retryCounts[orderId]++;
                setTimeout(() => delayedBroadcastOrderUpdate(order), 1000 * Math.pow(2, retryCounts[orderId] + 1));
                delete cooldownTracker[orderId];
            } else {
                delete timers[orderId];
                delete payloads[orderId];
                delete cooldownTracker[orderId];
                delete retryCounts[orderId];
            }

            return;
        }
        delete timers[orderId];
        delete payloads[orderId];
        setTimeout(() => {
            delete cooldownTracker[orderId];
        }, BROADCAST_COOLDOWN_SECONDS * 1000);
    }, BROADCAST_BUFFER_SECONDS * 1000);
}

const debouncedBroadcastOrderUpdate = delayedBroadcastOrderUpdate;

const getStorageId = (orderId = '') => `${window.locationId}-order-${orderId}`;
const getSettlementStorageId = (orderId = '') => `${window.locationId}-settlement-${orderId}`;

function getActiveOrder(state) {
    if (!state.activeOrderId) return { orders: [] };

    const activeOrder = state.orders.find(o => o && o._id == state.activeOrderId);
    if (!activeOrder) return { orders: [] };

    if (activeOrder.tableMergedWith) {
        // DO NOT REMOVE. OLD LOGIC POS2-1450
        // const mergees = getters.pendingOrders
        //     .filter(order => order.tableId && activeOrder.tableMergedWith.includes(order.tableId));

        //get last order with table id or the mergee
        let mergees = [];
        activeOrder.tableMergedWith.forEach(tableId => {
            let mergee = state.orders.findLast(order => order.tableId && order.tableId == tableId);

            if(mergee) {
                mergees.push(mergee);
            }
        });


        // DO NOT REMOVE. OLD LOGIC POS2-1450
        // const totals = mergees.reduce((acc, order) => {
        //     return {
        //         // total: acc.total + order.totals.total,
        //         // tax: acc.tax + order.totals.tax,
        //         // serviceCharge: acc.serviceCharge + order.totals.serviceCharge,
        //         // discount: acc.discount + order.totals.discount,
        //         // subTotal: acc.subTotal + order.totals.subTotal,

        //         vat: acc.vat + order.totals.vat,
        //         serviceCharge: acc.serviceCharge + order.totals.serviceCharge,
        //         net: acc.net + order.totals.net,
        //         total: acc.total + order.totals.total,
        //         beforeSc: acc.beforeSc + order.totals.beforeSc,
        //         rawPrice: acc.rawPrice + order.totals.rawPrice,
        //         vatExemptSales: null,
        //         zeroRatedSales: null
        //     };
        // }, activeOrder.totals);

        const totals = activeOrder.totals;

        const transactionCreatedAtByKot = mergees.reduce((acc, order) => {
            return { ...acc, ...order.transactionCreatedAtByKot };
        }, activeOrder.transactionCreatedAtByKot);

        return cloneDeep({
            ...activeOrder,
            kots: [
                ...activeOrder.kots,
                ...map(mergees, 'kots').flat(),
            ],
            kotBreakdowns: [
                ...activeOrder.kotBreakdowns,
                ...map(mergees, 'kotBreakdowns').flat(),
            ],
            orders: [
                ...activeOrder.orders,
                ...map(
                    map(mergees, m => m.orders.map(o => ({ ...o, parentOrderId: m._id }))).flat(),
                    order => ({ ...order, isMerged: true }),
                ),
            ],
            totals,
            transactionCreatedAtByKot,
        });
    }

    return activeOrder;
}

function idLineItems(order) {
    if (!order.orders) return order;

    order.orders.forEach((lineItem, index) => {
        if (lineItem._id) return;
        lineItem._id = new Date().getTime() + index;
        order.orders[index] = lineItem;
    });

    return order;
}

const checkIfHasWarnings = (response) => {
    if(response.data.duplicateKOT) {
        swal({
            title: response.data.duplicateKOT,
            icon: "warning",
            dangerMode: true,
        });
    }
}

function defaultStateFactory() {
    return {
        orders: [],
        settlements: [],
        activeOrderId: '',
        activeBrandId: '',
        activeServiceTypeId: '',
        activeChannelId: '',
        lastKot: 0,
        lastBillNum: 0,
        lastReceiptNum: 0,
        offlineTransactionCount: 0,
        pendingSettlements: {},
        dataContainer: {},
        lastMTEventTimeStamp: new Date().getTime(),
        syncInterval: '',
        lastEndingVoidOrder: {},
        lastVoidBillNum: 0,
        lastVoidReceiptNum: 0,
        activeOrder: { orders: [] },
        isOrdersLoaded: false,
        isSettlementsLoaded: false,
        unsyncVoidedBillCount: 0,
        lastBroadcastTimestamp: new Date().getTime(),
        latestDeliveryOrders: [],
        latestCancelledDeliveryOrders: [],
        latestOnlinePayment: null,
        latestDineInOrders: [],
        latestDineInOnlinePayment: null,
        queueStatus: {},
        isLocalSeriesValidated: false,
    };
}

export default {
    state: defaultStateFactory(),

    mutations: {
        resetState(state) {
            Object.assign(state, defaultStateFactory());
        },

        setOrders(state, orders) {
            const payloadOrders = idLineItems(toNumbersDeep(orders));
            state.orders = payloadOrders;

            if (USE_DEXIE_STORAGE) {
                storeOrders(payloadOrders.map(p => ({ ...p, locationId: window.locationId })));
            } else if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                payloadOrders.forEach(order => safeLocalForageSet(getStorageId(order._id), order));
            }
        },

        setQueueStatus(state, queueStatus) {
            state.queueStatus = queueStatus;
        },

        refreshActiveOrder(state) {
            state.activeOrder = getActiveOrder(state);
        },

        setIsLocalSeriesValidated(state, value) {
            state.isLocalSeriesValidated = value;
        },

        updateOrder(state, { orderId, order, isFromBroadcast = false, forceSync = false, forceFullBroadcast = false }) {
            if ('orders' in order) {
                order.orders = order.orders.filter(o => !o.isMerged);
            }

            if (isEqual(
                omit(order, ['updatedAt']),
                omit(pick(state.orders.find(o => o && o._id == orderId), Object.keys(order)), ['updatedAt']),
            )) {
                return;
            }

            const index = state.orders.findIndex(o => o && o._id == orderId);

            if (OFFLOAD.sqliteOffloadReceipt) {
                if (index > -1) {
                    state.orders[index] = idLineItems({
                        ...state.orders[index],
                        ...toNumbersDeep(order),
                        updatedAt: isFromBroadcast ? order.updatedAt : new Date().getTime(),
                    });

                    if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                        safeLocalForageSet(getStorageId(orderId), state.orders[index]);
                    }

                    if (orderId == state.activeOrderId) {
                        state.activeOrder = getActiveOrder(state);
                    }
                }

                return;
            }

            if (index == -1) {
                if (!isFromBroadcast) {
                    debouncedBroadcastOrderUpdate({
                        _id: orderId,
                        ...order,
                        locationId: state.user.locationId ?? window.locationId,
                        updatedAt: order.updatedAt ?? new Date().getTime(),
                        isFirstBroadcast: true,
                    });

                    state.lastBroadcastTimestamp = new Date().getTime();
                }

                return;
            }

            if (isFromBroadcast && state.orders[index].updatedAt >= order.updatedAt && !forceSync) return;

            if (index > -1) {
                state.orders[index] = idLineItems({
                    ...state.orders[index],
                    ...toNumbersDeep(order),
                    updatedAt: isFromBroadcast ? order.updatedAt : new Date().getTime(),
                });

                if (USE_DEXIE_STORAGE) {
                    storeOrder({
                        ...state.orders[index],
                        locationId: window.locationId,
                    });
                } else if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                    safeLocalForageSet(getStorageId(orderId), state.orders[index]);
                }

                if (orderId == state.activeOrderId) {
                    state.activeOrder = getActiveOrder(state);
                }
            }

            if (!isFromBroadcast && !state.orders[index].isOnlineDelivery) {
                let payload = {};
                if (index > -1) {
                    payload = USE_OPTIMIZED_BROADCAST || forceFullBroadcast
                        ? state.orders[index]
                        : pick(state.orders[index], Object.keys(order));
                } else {
                    payload = order;
                }

                debouncedBroadcastOrderUpdate({
                    _id: orderId,
                    ...payload,
                    locationId: state.user.locationId ?? window.locationId,
                    isFirstBroadcast: USE_OPTIMIZED_BROADCAST,
                    updatedAt: new Date().getTime()
                });
                state.lastBroadcastTimestamp = new Date().getTime();
            }
        },

        deleteOrder(state, orderId) {
            const index = state.orders.findIndex(o => o._id == orderId);
            if (index == -1) return;

            debouncedBroadcastOrderUpdate({
                _id: orderId,
                ...(state.orders[index] || {}),
                isDeleted: true,
                updatedAt: new Date().getTime(),
            });
            state.lastBroadcastTimestamp = new Date().getTime();

            state.orders.splice(index, 1);

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                localForage.removeItem(getStorageId(orderId));
            }

            if (orderId == state.activeOrderId) {
                state.activeOrderId = '';
                state.activeOrder = { orders: [] };
            }
        },

        addOrder(state, order) {
            const payload = idLineItems(toNumbersDeep(omit(order, ['isFromBroadcast'])));
            payload.updatedAt ??= new Date().getTime();
            state.orders.push(payload);

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                safeLocalForageSet(getStorageId(order._id), payload);
            }

            if (!order.isFromBroadcast && !OFFLOAD.sqliteOffloadReceipt) {
                trackOrder(order._id);
                debouncedBroadcastOrderUpdate({ ...payload, isFirstBroadcast: true });
                state.lastBroadcastTimestamp = new Date().getTime();
            }
        },

        updateOrderLineItem(state, { orderId, lineItemId, lineItem, isFromBroadcast = false }) {
            const oIndex = state.orders.findIndex(o => o._id == orderId);
            if (oIndex == -1) return;

            const index = state.orders[oIndex].orders.findIndex(o => o._id == lineItemId);
            if (index == -1) {
                if (lineItem.isDeleted) return;
                state.orders[oIndex].orders.push(lineItem);
            } else if (lineItem.isDeleted) {
                state.orders[oIndex].orders.splice(index, 1);
            } else {
                state.orders[oIndex].orders[index] = {
                    ...state.orders[oIndex].orders[index],
                    ...toNumbersDeep(lineItem),
                };
            }

            state.orders[oIndex].updatedAt = new Date().getTime();

            if (USE_DEXIE_STORAGE) {
                updateOrder(orderId, state.orders[oIndex]);
            } else if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                safeLocalForageSet(getStorageId(orderId), state.orders[oIndex]);
            }

            if (orderId == state.activeOrderId || state.orders[oIndex].tableMergedWith) {
                state.activeOrder = getActiveOrder(state);
            }

            if (!isFromBroadcast) {
                debouncedBroadcastOrderUpdate({ _id: orderId, ...(state.orders[oIndex] || {}) });
                state.lastBroadcastTimestamp = new Date().getTime();
            }
        },

        setIsOrdersLoaded(state, isOrdersLoaded) {
            state.isOrdersLoaded = isOrdersLoaded;
        },

        setIsSettlementsLoaded(state, isSettlementsLoaded) {
            state.isSettlementsLoaded = isSettlementsLoaded;
        },

        setSettlements(state, settlements) {
            state.settlements = settlements;
            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                settlements.forEach(settlement => safeLocalForageSet(getSettlementStorageId(settlement.orderId), settlement));
            }
        },

        addSettlement(state, settlement) {
            state.settlements.push(settlement);

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                const key = getSettlementStorageId(settlement.orderId);
                safeLocalForageSet(key, settlement);
            }
        },

        updateSettlementByOrderId(state, { orderId, settlement }) {
            const index = state.settlements.findIndex(s => s.orderId == orderId);
            state.settlements[index] = {
                ...state.settlements[index],
                ...settlement,
            };

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                safeLocalForageSet(getSettlementStorageId(orderId), state.settlements[index]);
            }
        },

        updateSettlementByBillNum(state, { billNum, settlement }) {
            const index = state.settlements.findIndex(s => s.bill_num == billNum);
            state.settlements[index] = {
                ...state.settlements[index],
                ...settlement,
            };

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                safeLocalForageSet(getSettlementStorageId(state.settlements[index].orderId), state.settlements[index]);
            }
        },

        deleteSettlementByOrderId(state, orderId) {
            const index = state.settlements.findIndex(s => s.orderId == orderId);
            state.settlements.splice(index, 1);

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                localForage.removeItem(getSettlementStorageId(orderId));
            }
        },

        deleteSettlementByBillNum(state, billNum) {
            const index = state.settlements.findIndex(s => s.bill_num == billNum);

            if (index == -1) return;

            const orderId = state.settlements[index].orderId;
            state.settlements.splice(index, 1);

            if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                localForage.removeItem(getSettlementStorageId(orderId));
            }
        },

        setActiveOrderId(state, id) {
            state.activeOrderId = id;
            state.activeOrder = getActiveOrder(state);
        },

        setActiveOrder(state, data) {
            state.activeOrder = data;
        },

        clearActiveOrderId(state) {
            state.activeOrderId = '';
            state.activeOrder = { orders: [] };
        },

        setActiveBrandId(state, id) {
            state.activeBrandId = id;
        },

        setActiveServiceTypeId(state, id) {
            state.activeServiceTypeId = id;
        },

        setActiveChannelId(state, id) {
            state.activeChannelId = id;
        },

        // reset lastKot either the highest KOT from pending orders or the last KOT fetched from DB
        resetLastKot(state, lastKotFromDB) {
            const pendingKots = state.orders.reduce((kots, order) => ([...kots, ...(order.kots || [])]), []);
            const highestPendingKot = Math.max(...pendingKots) ;

            state.lastKot = highestPendingKot > lastKotFromDB ? highestPendingKot : lastKotFromDB;
        },

        setLastKot(state, kot) {
            state.lastKot = kot;
        },

        setLastEndingVoidOrder(state, orderId) {
            const index = state.orders.findIndex(o => o._id == orderId);
            state.lastEndingVoidOrder = state.orders[index];
        },

        setOfflineTransactionCount(state, count) {
            state.offlineTransactionCount = count;
        },

        incrementOfflineTransactionCount(state) {
            state.offlineTransactionCount++;
        },

        incrementLastBillNum(state) {
            state.lastBillNum++;
        },

        setLastBillNum(state, value) {
            state.lastBillNum = value;
        },

        incrementLastReceiptNum(state) {
            state.lastReceiptNum++;
        },

        setLastReceiptNum(state, value) {
            state.lastReceiptNum = value;
        },

        incrementLastVoidBillNum(state) {
            state.lastVoidBillNum++;
        },

        setLastVoidBillNum(state, value) {
            state.lastVoidBillNum = value;
        },

        incrementLastVoidReceiptNum(state) {
            state.lastVoidReceiptNum++;
        },

        setLastVoidReceiptNum(state, value) {
            state.lastVoidReceiptNum = value;
        },

        async clearOrders(state) {
            state.orders = [];
            if (USE_DEXIE_STORAGE) {
                deleteOrdersByLocationId(window.locationId);
            } else if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
                const lfKeys = await localForage.keys();
                lfKeys.forEach(key => {
                    if (key.startsWith(getStorageId())) localForage.removeItem(key);
                });
            }
        },

        clearSettlements(state) {
            state.settlements = [];
        },

        setPendingSettlements(state, { orderId, settlement }) {
            state.pendingSettlements[orderId] = merge(
                state.pendingSettlements[orderId],
                settlement,
            );
        },

        clearPendingSettlementByOrderId(state, orderId) {
            delete state.pendingSettlements[orderId];
        },

        setOfflineTransactionCountToZero(state) {
            state.offlineTransactionCount = 0;
        },

        addOrUpdateDataContainer(state, { key, value, mode}) {
            if(mode == 'delete') {
                delete state.dataContainer[key];
            } else {
                state.dataContainer[key] = value;
            }
        },

        clearPendingSettlements(state) {
            state.pendingSettlements = {};
        },

        bumpLastMTEventTimestamp(state) {
            state.lastMTEventTimeStamp = new Date().getTime();
        },

        setState(state, value) {
            Object.keys(value).forEach(k => {
                state[camelCase(k)] = value[k];
            });
        },

        setSynchInterval(state, data) {
            state.syncInterval = data;
        },

        setUnsyncVoidedBillCount(state, data) {
            state.unsyncVoidedBillCount = data;
        },

        setLatestDeliveryOrders(state, data) {
            state.latestDeliveryOrders = data;
        },

        setLatestCancelledDeliveryOrders(state, data) {
            state.latestCancelledDeliveryOrders = data;
        },

        setLatestOnlinePayment(state, data) {
            state.latestOnlinePayment = data;
        },

        setLatestDineInOrders(state, data) {
            state.latestDineInOrders = data;
        },

        setLatestDineInOnlinePayment(state, data) {
            state.latestDineInOnlinePayment = data;
        }
    },

    actions: {
        voidOrder({ commit }, { orderId, voidApprover, voidReason }) {
            commit('updateOrder', { orderId, order: { isVoided: true, voidApprover, voidReason } });
        },

        async getLatestQueueStatus({ commit }) {
            // skip if sqliteOffloadReceipt is enabled
            if (OFFLOAD.sqliteOffloadReceipt) {
                return;
            }

            try {
                const response = await getLatestOrders(null, true);
                commit('setQueueStatus', response.data.queueStatus);
            } catch (e) {
                console.error(e);
            }
        },

        async getLatestServerOrders({ rootState, dispatch, state, commit }, { lastFetchTimeStamp = undefined, isForceSyncing = false, isRefresh = false} = {}) {
            // skip if sqliteOffloadReceipt is enabled
            if (OFFLOAD.sqliteOffloadReceipt) {
                return;
            }

            if(isForceSyncing) return;
            try {
                const response = await getLatestOrders(lastFetchTimeStamp);
                const { orders, queueStatus } = response.data;
                commit('setQueueStatus', queueStatus);
                orders.forEach(order => {
                    if (order && (!lastFetchTimeStamp || order.updatedByTerminalId != rootState.user.terminalId || order.isOnlineDelivery)) {
                        order.isSettled = order.isSettled || (Boolean(order?.receipt_num) && !order?.isOnlineDelivery);
                        if (order?.isOnlineDelivery) {
                            seriesService.setLastKotNum(Math.max(...order.kots));
                            seriesService.setLastBillNum(order.bill_num);
                            seriesService.setLastReceiptNum(order?.receipt_num || 0);
                            seriesService.setLastVoidBillNum(order?.void_bill_num || 0);
                            seriesService.setLastVoidReceiptNum(order?.void_receipt_num || 0);
                        }
                        dispatch('upsertOrder', { orderId: order._id, order, isFromBroadcast: true });
                        if (order._id == state.activeOrderId) {
                            if ((order.isVoided || order.isCancelled || order.isSettled) && !order?.isOnlineDelivery) commit('clearActiveOrderId');
                        }
                    }
                });

                if (ENABLE_SALES_CONSOLIDATOR && response.data?.currentServerPosDate) {
                    bus.emit("executeLogoutIfPosDateMismatch", response.data?.currentServerPosDate);
                }

                if(response.data?.latestDeliveryOrders?.length) {
                    if(!isEqual(state.latestDeliveryOrders, response.data.latestDeliveryOrders)) {
                        commit('setLatestDeliveryOrders', response.data.latestDeliveryOrders);
                    }
                }

                if(response.data?.latestCancelledDeliveryOrders?.length) {
                    if(!isEqual(state.latestCancelledDeliveryOrders, response.data.latestCancelledDeliveryOrders)) {
                        commit('setLatestCancelledDeliveryOrders', response.data.latestCancelledDeliveryOrders);
                    }
                }

                if(response.data?.latestOnlinePayment) {
                    if(!isEqual(state.latestOnlinePayment, response.data.latestOnlinePayment)) {
                        commit('setLatestOnlinePayment', response.data.latestOnlinePayment);
                    }
                }

                if (response.data?.latestDineInOrders?.length) {
                    if(!isEqual(state.latestDineInOrders, response.data.latestDineInOrders)) {
                        commit('setLatestDineInOrders', response.data.latestDineInOrders);
                    }
                }

                if (response.data?.latestDineInOnlinePayment) {
                    if(!isEqual(state.latestDineInOnlinePayment, response.data.latestDineInOnlinePayment)) {
                        commit('setLatestDineInOnlinePayment', response.data.latestDineInOnlinePayment);
                    }
                }

                if (ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE && response.data?.hasPerformShiftChange) {
                    bus.emit("executeLogoutIfTerminalOnePerformsShiftChange", response.data?.hasPerformShiftChange);
                }

                return orders;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        async forceSyncSingleOrder({ dispatch, commit }, currentOrder) {
            // skip if sqliteOffloadReceipt is enabled
            if (OFFLOAD.sqliteOffloadReceipt) {
                return;
            }

            try {
                const response = await syncOrder(currentOrder);

                const { order } = response.data;

                order.isSettled = order.isSettled || (Boolean(order?.receipt_num) && !order?.isOnlineDelivery);
                dispatch('upsertOrder', { orderId: order._id, order, isFromBroadcast: true, forceSync: true });
                commit('deleteSettlementByBillNum', currentOrder.bill_num);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        async forceSyncOrders({ state, dispatch }, posDate) {
            // skip if sqliteOffloadReceipt is enabled
            if (OFFLOAD.sqliteOffloadReceipt) {
                return;
            }

            try {
                const locationId = state.user.locationId || window.locationId;
                const payload = locationId
                    ? state.orders.filter(o => o.locationId && o.locationId == locationId)
                    :  [...state.orders];

                const response = await forceSyncOrder(posDate, payload);
                const orders = response?.data?.orders || [];
                orders.forEach(order => {
                    order.isSettled = order.isSettled || (Boolean(order?.receipt_num) && !order?.isOnlineDelivery);
                    dispatch('upsertOrder', { orderId: order._id, order, isFromBroadcast: true, forceSync: true });
                });
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        async upsertOrder({ commit, state }, { orderId, order, isFromBroadcast = false, forceSync = false }) {
            const existing = state.orders.find(o => o._id == orderId);
            const existingIsNewer = existing && order.updatedAt < existing.updatedAt;

            if (existingIsNewer) {
                delayedBroadcastOrderUpdate(existing);
            }

            if (order.isSync && !order.isOnlineDelivery) {
                await storeSyncedOrder(trimSyncedOrder(order));
                if (existing) bus.emit("resetTables", order.tableId);
                commit('deleteOrder', orderId);
                return;
            }

            if (order.isDeleted) {
                commit('deleteOrder', orderId);
                if (order.updatedAt > state.lastMTEventTimeStamp) {
                    commit('bumpLastMTEventTimestamp');
                }
                return;
            }

            if (existing) {
                if (!ENABLE_BROADCAST_OPTIMIZATION || order.isOnlineDelivery || window.isSharedTerminal) {
                    commit('updateOrder', { orderId, order, isFromBroadcast });
                }
            } else {
                commit('addOrder', { ...order, isFromBroadcast });
            }

            const hasChanges = !existing || order.updatedAt > existing.updatedAt;

            if (isFromBroadcast && hasChanges) {
                commit('bumpLastMTEventTimestamp');
            }
        },

        async getOrderByBillNum({ state }, billNum) {
            return state.orders.find(o => o.bill_num == billNum || o.splits?.find(s => s.bill_num == billNum));
        },

        voidTransaction({ commit, state }, { orderId, transactionKot, voidApprover, voidReason }) {
            const parentOrder = cloneDeep(state.orders.find(o => o._id === orderId));
            parentOrder.orders = parentOrder.orders.map(o => ({
                ...o,
                isVoided: o.kot === transactionKot,
                voidApprover: o.kot === transactionKot ? voidApprover : '',
                voidReason: o.kot === transactionKot ? voidReason : '',
            }));

            parentOrder.voidedKots = [...(parentOrder.voidedKots || []), transactionKot];

            commit('updateOrder', {
                orderId,
                order: parentOrder,
            });
        },

        voidLineItem({ commit, state }, { orderId, lineItemIndex, voidQty, voidApprover, voidReason }) {
            const parentOrder = cloneDeep(state.orders.find(o => o._id === orderId));
            const targetLineItem = parentOrder.orders[lineItemIndex];

            if (targetLineItem.quantity > voidQty) {
                parentOrder.orders.push({
                    ...targetLineItem,
                    quantity: voidQty,
                    isVoided: true,
                    voidReason,
                    voidApprover,
                });

                parentOrder.orders[lineItemIndex].quantity -= voidQty;
            } else {
                parentOrder.orders[lineItemIndex] = {
                    ...targetLineItem,
                    isVoided: true,
                    voidReason,
                    voidApprover,
                };
            }

            commit('updateOrder', {
                orderId: this.orderId,
                order: parentOrder,
            });
        },

        async restoreOrdersFromLocalForage({ commit, state }) {
            if (USE_DEXIE_STORAGE) {
                const orders = await getOrdersByLocationid(window.locationId);
                commit('setOrders', orders);
                commit('setIsOrdersLoaded', true);
                return;
            }

            if (state.isOrdersLoaded || !USE_EXPERIMENTAL_STORAGE_STRATEGY) return;

            const keyPrefix = getStorageId();
            const keys = await localForage.keys();

            const orders = await Promise.all(keys.map(async key => {
                if (key.startsWith(keyPrefix)) {
                    return await localForage.getItem(key);
                }
            }));

            commit('setOrders', orders.filter(o => o));
            commit('setIsOrdersLoaded', true);
        },

        async restoreSettlementsFromLocalForage({ commit, state }) {
            if (state.isSettlementsLoaded || !USE_EXPERIMENTAL_STORAGE_STRATEGY) return;

            const keyPrefix = getSettlementStorageId();
            const keys = await localForage.keys();

            const settlements = await Promise.all(keys.map(async key => {
                if (key.startsWith(keyPrefix)) {
                    return await localForage.getItem(key);
                }
            }));

            commit('setSettlements', settlements.filter(o => o));
            commit('setIsSettlementsLoaded', true);
        },

        /**
        *   params: object with the following properties
        *       order: (optional) the order object you want to recompute totals
        *       orderId: (if order is not used this should be required) the orderId of the order
        *                   to recompute totals
        *       updateOrder: (optional Booelan) if the order should be updated
        *   return: object
        *       item_totals - the totals of each item in the order
        *       grand_totals - the over all total of the order
        **/
        recomputeTotals({ commit, getters }, payload) {
            let targetOrder = null;
            //check if new order
            if ('order' in payload) {
                targetOrder = payload.order;
            } else {
                targetOrder = getters.targetOrder(payload.orderId);
            }

            const recomputeRes = totalsComputationInstance.recomputeTotals(targetOrder);

            const newTotals = recomputeRes.grandTotals;

            if(payload.updateOrder) {
                commit('updateOrder', {
                    orderId: payload.orderId,
                    order: {
                        totals: newTotals,
                    },
                    isFromBroadcast: true,
                });
            }

            /**
             * return value:
             * item_totals = totals of each item
             * grandTotals = the grand totals of all the items combined
             */

            return recomputeRes;
        },

        regenerateOrderTotals({ dispatch, commit }, { orderId, key = 'totals' }) {
            const newTotals = dispatch('recomputeTotals', { orderId });
            commit('updateOrder', {
                orderId,
                order: { [key]: newTotals.grandTotals },
            });
        },

        async sqliteUpsertReceipt({ state, commit }, { orderId, action, order = null }) {
            const index = state.orders.findIndex(o => o && o._id == orderId);

            if (index >= 0) {
                order = order || state.orders[index];

                const receiptOffload = new ReceiptBridge();
                await receiptOffload.upsertReceipt(action, order);

                const { SETTLE_ORDER, BILL_VOID, PENDING_VOID } = OFFLOAD_RECEIPT_ACTION;
                const hasMosaicPayQRPH = order?.payments?.some(payment => payment?.type === "mosaicpay_qrph");

                if([SETTLE_ORDER, BILL_VOID].includes(action) || (PENDING_VOID === action && order?.isVoided)) {

                    // prevent clearing of active order for paymongo.
                    // previous behavior clears the order panel products & pricing totals preventing user to click the print button.
                    if (!hasMosaicPayQRPH) {
                        commit('deleteOrder', orderId);
                    }

                    if (OFFLOAD.sqliteOffloadPOSFE1300
                        && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM
                        && !isEmpty(order?.receiptMergedWith)
                    ) {
                        for (const id of order.receiptMergedWith || []) {
                            commit('deleteOrder', id);
                        }
                    }
                }
            }
        },

        async sqliteFetchPendingOrders({ commit, dispatch }) {
            const ob = new OrderBridge();
            const orders = await ob.getPendingOrders();

            commit('clearOrders');
            orders.forEach(o => {
                const order = JSON.parse(o.order_data);
                dispatch('upsertOrder', { orderId: order._id, order });
            });
        },

        async sqliteOffloadSync({ state, dispatch }, { receipts, reloadSqliteBillPaged = true }) {
            const rb = new ReceiptBridge();
            const rcb = new ReceiptContentBridge();

            const updateReceipts = async (receipts, is_syncing = false) => {
                if (OFFLOAD.useGetReceipts) {
                    // Clone receipts array and set is_syncing to true
                    const modifiedReceipts = cloneDeep(receipts).map(receipt => {
                        return {
                            local_id: receipt.local_id,
                            is_syncing
                        };
                    });

                    // Update data with modified receipts
                    await rb.updateData(modifiedReceipts);
                }
            }

            if (receipts.length > 0) {
                receipts = await dispatch('processEmptyReceiptContents', { receipts });
                receipts = receipts.filter(obj => Object.keys(obj).length > 0);
                if (receipts.length > 0) {
                    try {
                        await updateReceipts(receipts, true);

                        // Offload syncing
                        const response = await sqliteOffloadSync({ receipts });

                        // Process response data
                        const data = response.data.map(receipt => {
                            if (OFFLOAD.useGetReceipts) {
                                receipt.is_syncing = false;
                            }

                            return receipt;
                        });

                        // Update data and receipt contents
                        if (data.length > 0) {
                            await rb.updateData(data);
                            data.forEach(datum => rcb.updateData(datum.receipt_contents));
                        }

                        // Update receipts lastFetchAt flag so we don't re-fetch
                        // these receipts on next login
                        const storage = new ScopedNativeStorage(window.locationId);
                        storage.put('receiptLastFetchAt', moment().format('YYYY-MM-DD HH:mm:ss'));

                        if (reloadSqliteBillPaged) {
                            bus.emit("reloadSqliteOffloadReceiptsPaged");
                        }

                        return response;
                    } catch (e) {
                        await updateReceipts(receipts);
                        throw e;
                    }
                }
            }

            bus.emit("offloadSyncProcessStarted", false);
            return null;
        },

        async processEmptyReceiptContents({ state }, { receipts }) {
            const ob = new OrderBridge();
            const rBridge = new ReceiptBridge();
            const rcBridge = new ReceiptContentBridge();
            const promises = [];
            for (const [index, receipt] of receipts.entries()) {
                if (receipt?.is_integration_order) {
                    continue;
                }

                // If 'generated_void_amount' is null, unset it to avoid integrity constraint violations
                if (!receipt?.generated_void_amount) {
                    delete receipts[index].generated_void_amount;
                }

                if (receipt.receipt_contents.length === 0) {
                    const order = await ob.getOrderById(receipt.order_id);

                    const receiptPromise = (async () => {
                        if (!isEmpty(order?.order_data) && order.transaction_status_id === TRANSACTION_STATUS_ID.PAID) {
                            const retryCount = parseInt(order?.retry_count ?? 0) + 1;
                            await rBridge.upsertReceipt(
                                OFFLOAD_RECEIPT_ACTION.SETTLE_ORDER,
                                JSON.parse(order.order_data),
                                retryCount
                            );

                            receipts[index] = await rBridge.getReceiptByLocalId(receipt.order_id);

                            const { rows: modifiedReceiptContents } = await rcBridge.getRow({
                                where: {
                                    receipt_local_id: receipt.order_id
                                }
                            });

                            receipts[index].receipt_contents = modifiedReceiptContents;

                            order.order_data = JSON.parse(order?.order_data ?? '{}');
                            await storeToS3({ receipt: receipt, order }, "SQLite-Empty-Receipt-Contents", receipt.location_id);
                        }
                    })();

                    promises.push(receiptPromise);
                }
            }

            await Promise.all(promises);

            return receipts;
        },

        async checkAndSyncSqliteOffloadTables({ dispatch }) {
            const storage = new ScopedNativeStorage(window.locationId);

            for (const key in sqliteOffloadBridges) {
                const table = sqliteOffloadBridges[key];

                const bridge = table.bridge;
                const result = await bridge.getAll();
                const rows = result?.rows ?? result?.data ?? result;

                if (rows.length === 0) {
                    storage.put(table.lastFetchKey, '');
                }

                const lastFetchAt = await storage.get(table?.lastFetchKey) || "";
                const response = await axios.get(`/cashier/offline_items/${key}`, {
                    headers: { 'Content-Type': 'application/json' },
                    params: {
                        enableSqliteIntegration: true,
                        lastFetchAt
                    },
                });

                const { values } = response?.data[0] ?? {};

                if (values) {
                    bridge.bulkImport(values)
                        .then(() => {
                            console.log(`${key} loaded into SQLite!`);

                            storage.put(table.lastFetchKey, generateDateTime());
                        })

                    if (key === 'locations') {
                        dispatch('user/populatePermissions', { root: true });
                    }
                }
            }
        },
        async getLatestIntegrationOrders({ dispatch, state, commit }) {
            try {
                const ob = new OrderBridge();
                const rb = new ReceiptBridge();
                const rcb = new ReceiptContentBridge();
                const response = await getLatestOrders();

                let {integration_orders, latestOnlinePayments} = response.data;

                for (const datum of integration_orders) {
                    const order = datum.order;
                    const receiptContents = datum.receipts.flatMap(receipt => receipt.receipt_contents);

                    // Find the first receipt that is not original
                    const receipt = datum.receipts.find(receipt => receipt.transaction_status_id != TRANSACTION_STATUS_ID.ORIGINAL);
                    const transaction = receipt.receipt_contents.find(item => item.type === RECEIPT_CONTENT_TYPE.TRANSACTIONS);

                    // Skip processing if the receipt is already billed, settled or voided
                    if ((receipt.transaction_status_id === TRANSACTION_STATUS_ID.BILLED && receipt?.bill_num)
                        || (receipt.transaction_status_id === TRANSACTION_STATUS_ID.PAID && receipt?.receipt_num)
                        || (receipt.transaction_status_id === TRANSACTION_STATUS_ID.VOIDED && receipt?.void_bill_num)
                    ) {
                        continue;
                    }

                    // Check if the order is billed, has empty KOTs, and is not settled or voided
                    const isBilled = order?.isBilled;
                    const isEmptyKots = isEmpty(order.kots);
                    const isNotSettledOrVoided = !order?.isSettled || !order?.isVoided;

                    let incrementedKotNum = transaction.kot_num;

                    if (isBilled && !transaction.kot_num && isEmptyKots && isNotSettledOrVoided
                        && receipt.transaction_status_id === TRANSACTION_STATUS_ID.BILLED
                    ) {
                        // Increment the KOT number
                        incrementedKotNum = await seriesService.getAndIncrementKotNum() + 1;

                        // Assign incremented KOT number to receipt contents of type TRANSACTIONS
                        receiptContents.forEach(rc => {
                            if (rc.type === RECEIPT_CONTENT_TYPE.TRANSACTIONS) {
                                rc.kot_num = incrementedKotNum.toString();
                            }
                        });
                    }

                    // Assign incremented KOT number to each order
                    order.orders.forEach(o => {
                        o.kot = incrementedKotNum;
                    });

                    // Set the KOTs array with the incremented KOT number
                    order.kots = [incrementedKotNum];

                    let voidBillNum = null;
                    if (!receipt.void_bill_num && order?.isVoided) {
                        voidBillNum = await seriesService.getAndIncrementVoidBillNum() + 1;
                    }

                    // Process each receipt
                    for (const obj of datum.receipts) {
                        // Remove receipt_contents property
                        delete obj.receipt_contents;

                        order.bill_num = obj.bill_num;
                        order.receipt_num = obj.receipt_num;
                        order.void_bill_num = obj.void_bill_num;

                        // Generate bill_num if not present and order is billed
                        if (!receipt.bill_num && isBilled) {
                            obj.bill_num = await seriesService.getAndIncrementBillNum() + 1;
                            order.bill_num = obj.bill_num;
                        }

                        // Generate receipt_num if not present and order is settled
                        if (!receipt.receipt_num && order?.isSettled) {
                            obj.receipt_num = await seriesService.getAndIncrementReceiptNum() + 1;
                            order.receipt_num = obj.receipt_num;
                        }

                        // Generate void_bill_num if not present and order is voided
                        if (voidBillNum) {
                            obj.void_bill_num = voidBillNum;
                            order.void_bill_num = voidBillNum;
                        }

                        obj.is_integration_order = true;
                    }

                    await ob.upsertOrder(order);
                    await rb.bulkImport(datum.receipts);
                    await rcb.bulkImport(receiptContents);

                    // Perform auto sync API call
                    const receipts = await rb.getReceipts({
                        whereIn: {
                            local_id: datum.receipts.map(receipt => receipt.order_id).join()
                        }
                    });

                    // Dispatch SQLite offload sync action
                    await dispatch('sqliteOffloadSync', {
                        receipts,
                        reloadSqliteBillPaged: false
                    });
                }

                await dispatch('processEPayments', { latestOnlinePayments });

                // Function to update state if the data received from the response is different from the current state.
                const updateStateIfDifferent = (key) => {
                    const responseDataValue = response?.data?.[key];
                    if (responseDataValue) {
                        if (!isEqual(state[key], responseDataValue)) {
                            commit(`set${key.charAt(0).toUpperCase() + key.slice(1)}`, responseDataValue);
                        }
                    }
                }

                // Update different state values based on specific keys.
                updateStateIfDifferent('latestDeliveryOrders');
                updateStateIfDifferent('latestCancelledDeliveryOrders');
                updateStateIfDifferent('latestOnlinePayment');
                updateStateIfDifferent('latestDineInOrders');
                updateStateIfDifferent('latestDineInOnlinePayment');

                // Emit an event to reload sqlite offload receipts paged.
                if (!isEmpty(integration_orders) || !isEmpty(latestOnlinePayments)) {
                    bus.emit("reloadSqliteOffloadReceiptsPaged");
                }
            } catch (error) {
                console.log("Error fetching integration orders: ", error);
                if (isNetworkStable()) {
                    const payload = {
                        message: error.message,
                        name: "Integration Orders > " + error.name,
                        stack: error.stack,
                        url: window.location.href,
                    }
                    await storeToS3(payload, "SQLite-Errors", window.locationId);
                }
            }
        },
        async processEPayments({ dispatch }, { latestOnlinePayments }) {
            const ob = new OrderBridge();
            const rb = new ReceiptBridge();

            latestOnlinePayments = commonUtil.groupBy(latestOnlinePayments, "orderId");

            if (isEmpty(latestOnlinePayments)) {
                return;
            }

            for (let key in latestOnlinePayments) {
                const orderResponse = await ob.getOrderById(parseInt(key));
                const order = JSON.parse(orderResponse.order_data);

                // Ensure `order.payments` is initialized as an array
                if (!order.payments || !Array.isArray(order.payments)) {
                    order.payments = [];
                }

                const isFullyPaid = latestOnlinePayments[key].every(item => item?.isFullyPaid && item.status === "PAID");

                if (!isFullyPaid || order.payments?.some(payment => payment.type !== 'epayment')) {
                    return;
                }

                for (const invoice of latestOnlinePayments[key]) {
                    const {id, paymentTypeId, amount, paymentChannel, type} = invoice;

                    order.payments.push({
                        id: paymentTypeId,
                        amount,
                        change: 0,
                        exact_amount: amount,
                        method: type == "paymongo" ? "MosaicPay QRPH" : `E-PAYMENT (${paymentChannel})`,
                        payment_channel: type == "paymongo" ? '' : paymentChannel,
                        payment_invoice_id: id,
                        type: type == "paymongo" ? 'mosaicpay_qrph' : 'epayment',
                    });
                }

                const hasMosaicPayQRPH = order?.payments?.some(payment => payment?.type === "mosaicpay_qrph");

                order.isSettled = true;
                order.receipt_num = await seriesService.getAndIncrementReceiptNum() + 1;
                order.settledAt = await posDateWithCurrentTime();
                
                // no need to call this code for paymongo, this is causing duplicate receipt_contents since this action is being called upon manual settlement.
                // should only be applicable to xendit for now, since xendit handles automatic printing and redirect if fully paid.
                if (!hasMosaicPayQRPH) {
                    await dispatch('upsertOrder', { orderId: key, order });
                    await dispatch('sqliteUpsertReceipt', {
                        orderId: key,
                        action: OFFLOAD_RECEIPT_ACTION.SETTLE_ORDER,
                        order,
                    });
                }

                const receipts = await rb.getReceipts({
                    where: {
                        local_id: key
                    }
                });
            }
        }
    },

    getters: {
        activeOrder(state) {
            return state.activeOrder;
        },

        activeMergees(state, getters) {
            if (!getters.activeOrder.tableMergedWith) {
                return [];
            }

            return getters.pendingOrders.filter(order => getters.activeOrder.tableMergedWith.includes(order?.tableId));
        },

        pendingOrders(state) {
            return state.orders.filter(order => order && !order.isSettled && !order.isVoided);
        },

        pendingNotReopenedOrders(state) {
            return state.orders.filter(order => order && !order.isSettled && !order.isVoided && !order.isReopened);
        },

        activePendingSettlements(state) {
            return get(state.pendingSettlements, state.activeOrderId, {});
        },

        hasPendingSettlements(state, getters) {
            return !isEmpty(getters.activePendingSettlements);
        },

        targetOrder: (state) => (orderId) => {
            return cloneDeep(state.orders.find(o => o?._id === orderId));
        },
    },
}
