export const STATUSES = {
    VOID: 'Void',
    BILLED: 'Billed',
    PAID: 'Paid',
    PENDING: 'Pending',
};

// Infinity for now to "disable" hard limiting of transacting after limit reached
export const MAX_TRANSACTIONS = Infinity;

export const USE_EXPERIMENTAL_STORAGE_STRATEGY = true;

export const ENABLE_AVATAR = false;

export const PERMISSION_TYPES = {
    SALE: 'Sale',
    APPROVE: 'Approve',
    MANAGE: 'Manage',
    DAY_END: 'Day End',
    SHOW_SPOT_AUDIT: 'Payment Breakdown Spot Audit',
    X_READ: 'X-Read',
    REPRINT_Z_READ: 'Reprint Z-Read',
    EOD_REPORT: 'EOD Report',
    IS_STANDALONE: 'Is Standalone',
    IS_RECEIPT_BASED_LOCATION: 'Is receipt based location',
    HIDE_VAT_BREAKDOWN_IN_RECEIPT: 'Hide VAT Breakdown in Receipt',
    EMPLOYEE_CLOCK_IN_APPROVER_ON_SITE: 'Employee clock in, approver on site',
    PAX_DISABLED: 'Pax Disabled',
    CREATE_TRANSACTIONS: 'Create Transactions',
    SETTLE_TRANSACTIONS: 'Settle Transactions',
    LINE_ITEM_SEPARATOR: 'Line Item Separator',
    LABEL_PRINTING: 'Label Printing',
    REPORT_PAYMENT_BREAKDOWN_BY_CATEGORY: 'Report Payment Breakdown by Category',
    IKONOMIKAL_INTEGRATION: 'Ikonomikal Integration',
    CASH_FUND_MANAGEMENT: 'Cash Fund Management',
    GENERATE_OLD_ZREAD_FORMAT: 'Generate old Zread format',
    ENABLE_PRODUCT_SETTINGS: 'Enable Products Settings',
    PARENT_CHILD_CLASSIFICATION: 'Parent / Child Classification',
    ROOM_PAYMENT: 'Room Payment',
    REPRINT_PMIX: 'Reprint PMIX',
    PAST_TRANSACTIONS: 'Past Transactions',
    ALLOW_MULTI_TERMINAL: 'Allow Multi-Terminal',
    ENABLE_SQLITE_INTEGRATION: 'Enable SQLite Integration',
};

export const OR_PRINT_HEADERS = {
    STORE_COPY: 'Store Copy',
    CUSTOMER_COPY: 'Customer Copy',
}

export const PERMISSIONS = {
    // Role & Permission
    DAY_END: 'DAY_END',
    SHIFT_CHANGE: 'SHIFT_CHANGE',
    EOD_REPORT: 'EOD_REPORT',
    PLACE_ORDER: 'PLACE_ORDER',
    SETTLE_ORDER: 'SETTLE_ORDER',

    // Location
    PRINT_PMIX: 'PRINT_PMIX',
    PRINT_X_PMIX: 'PRINT_X_PMIX',
    EMPLOYEE_CLOCK_IN: 'EMPLOYEE_CLOCK_IN',
    PAX_DISABLED: 'PAX_DISABLED',
    SET_CASH_FLOAT: 'SET_CASH_FLOAT',
    APPLY_DISCOUNT_TO_SPLIT: 'APPLY_DISCOUNT_TO_SPLIT',
    LABEL_PRINTING: 'LABEL_PRINTING',
    OLD_ZREAD_FORMAT: 'OLD_ZREAD_FORMAT',
    ROOM_PAYMENT: 'ROOM_PAYMENT',
    REPRINT_PMIX: 'REPRINT_PMIX',
    CASH_FUND_MANAGEMENT: 'CASH_FUND_MANAGEMENT',
};

export const SYNC_STATUSES = {
    SYNCED: 'synced',
    SYNCING: 'syncing',
    UNSYNC: 'unsync',
    FULL: 'full',
};

export const PAYMENT_INVOICE_STATUSES = {
    PENDING: 'PENDING',
    PAID: 'PAID',
    EXPIRED: 'EXPIRED',
    PENDING_AND_PAID: 'PENDING,PAID',
    PENDING_PAID_FAILED_EXPIRED: 'PENDING,PAID,FAILED,EXPIRED',
    FAILED: 'FAILED'
};

export const POS_DATE_STATUSES = {
    MATCHED: 'matched',
    UNMATCH: 'unmatch',
    OVER: 'over',
};

export const DEXIE_TABLES = {
    LOCATIONS: 'locations',
    PERMISSIONS: 'permissions',
    USER_TYPE_PERMISSIONS: 'user_type_location_permissions',
    USERS: 'users',
    ATTENDANCES: 'attendances'
}

export const ORDER_PANEL_ACTION_BUTTONS = {
    SPLIT: 'split',
    LABEL_PRINT: 'label-print',
    ROOM_PAYMENT: 'room-payment'
};

export const SERIES_TYPES = {
    KOT_NUM: 'lastKotNum',
    BILL_NUM: 'lastBillNum',
    RECEIPT_NUM: 'lastReceiptNum',
    VOID_BILL_NUM: 'lastVoidBillNum',
    VOID_RECEIPT_NUM: 'lastVoidReceiptNum'
};

export const ACCOUNT_TYPES = {
    CLOUD: 'Cloud',
    BM: 'BM',
    HYBRID: 'Hybrid',
    QSR: 'QSR'
};

export const POST_BROADCAST_DELAY_SECONDS = 1;
export const BROADCAST_BUFFER_SECONDS = 0.5;
export const BROADCAST_COOLDOWN_SECONDS = 1;

export const CUSTOM_VISIBILITY_LIST = {
    BILL_SETTINGS: {
        showLineItemVoid: false,
    },
};

export const ACTIVE_ACCOUNT_TYPE = window.ACTIVE_ACCOUNT_TYPE;
export const GET_BREAKDOWNS_FROM_DB = true;

export const USE_DEXIE_STORAGE = false;

export const DELETE_SETTLEMENT_ON_SYNC = !window.isProd;

export const MAX_PRODUCT_COUNT_FOR_ALL = window.OFFLINE_MAX_PRODUCT_COUNT_FOR_ALL ?? 0;
export const ENABLE_SETTLEMENT_PERMISSION_CHECKING = window.ENABLE_SETTLEMENT_PERMISSION_CHECKING;
export const ENABLE_PLACE_ORDER_PERMISSION_CHECKING = window.ENABLE_PLACE_ORDER_PERMISSION_CHECKING;
export const ENABLE_POPULARITY_SORT = window.ENABLE_POPULARITY_SORT;
export const ENABLE_EMPLOYEE_CLOCK_IN_PERMISSION_CHECKING = window.ENABLE_EMPLOYEE_CLOCK_IN_PERMISSION_CHECKING;
export const ENABLE_USER_TYPE_PERMISSION_CHECKING = window.ENABLE_USER_TYPE_PERMISSION_CHECKING;
export const ENABLE_DINE_IN_TAKE_OUT_BUTTON = window.ENABLE_DINE_IN_TAKE_OUT_BUTTON;
export const ENABLE_LINE_ITEM_SEPARATORS = window.ENABLE_LINE_ITEM_SEPARATORS;
export const ENABLE_LINE_ITEM_SEPARATOR_EDITING = window.ENABLE_LINE_ITEM_SEPARATOR_EDITING;
export const ENABLE_LINE_ITEM_REORDERING = window.ENABLE_LINE_ITEM_REORDERING;
export const ENABLE_OTHER_CHARGES_OFFLINE = window.ENABLE_OTHER_CHARGES_OFFLINE;
export const ENABLE_IKONOMIKAL_INTEGRATION = window.ENABLE_IKONOMIKAL_INTEGRATION;
export const ENABLE_BROADCAST_OPTIMIZATION = window.ENABLE_BROADCAST_OPTIMIZATION;
export const ENABLE_PARENT_CHILD_CLASSIFICATION = window.ENABLE_PARENT_CHILD_CLASSIFICATION;
export const ENABLE_HIDE_NO_SERVICE_CHARGE = window.ENABLE_HIDE_NO_SERVICE_CHARGE;
export const ENABLE_UPDATED_GENERATED_BY = window.ENABLE_UPDATED_GENERATED_BY;
export const ENABLE_CASH_SALES_BREAKDOWN = window.ENABLE_CASH_SALES_BREAKDOWN;
export const ENABLE_UPDATED_REPORT_TIMESTAMP = window.ENABLE_UPDATED_REPORT_TIMESTAMP;
export const ENABLE_FORBID_SHIFT_CHANGE_NO_CASH_INPUT = window.ENABLE_FORBID_SHIFT_CHANGE_NO_CASH_INPUT;
export const ENABLE_FORBID_DAYEND_NO_CASH_INPUT = window.ENABLE_FORBID_DAYEND_NO_CASH_INPUT;
export const SAVE_LINE_ITEM_SEPARATOR_POSITIONS = window.SAVE_LINE_ITEM_SEPARATOR_POSITIONS;
export const LINE_ITEM_SEPARATOR_CHAR_COUNT = window.LINE_ITEM_SEPARATOR_CHAR_COUNT ?? 10;
export const OFFLINE_FORCE_EMPLOYEE_NO_SC = window.OFFLINE_FORCE_EMPLOYEE_NO_SC ?? false;
export const MAX_KOT_BEFORE_TRUNCATE_DISPLAY = window.MAX_KOT_BEFORE_TRUNCATE_DISPLAY ?? 4;
export const ENABLE_LOCATION_LOGGING = window.ENABLE_LOCATION_LOGGING;
export const ENABLE_PRODUCTS_SETTINGS = window.ENABLE_PRODUCTS_SETTINGS;
export const ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE = window.ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE;
export const ENABLE_INVALID_LOCAL_SERIES_ALERT = window.ENABLE_INVALID_LOCAL_SERIES_ALERT;
export const ENABLE_FORCE_SYNC = window.ENABLE_FORCE_SYNC;
export const IS_PUC_LOCATION = window.IS_PUC_LOCATION;
export const ENABLE_TAB_SQUARE = window.ENABLE_TAB_SQUARE;

export const LOCATION_ACCREDITATION_TYPE_ID = window.LOCATION_ACCREDITATION_TYPE_ID;
export const LOCATION_ACCREDITATION_TYPE = window.LOCATION_ACCREDITATION_TYPE?.toLowerCase();
export const LOCATION_PRINT_LAYOUT_OPTION = window.LOCATION_PRINT_LAYOUT_OPTION?.toLowerCase();
export const ENABLE_ZREAD_TEXT_FILE_GENERATION = window.ENABLE_ZREAD_TEXT_FILE_GENERATION && LOCATION_ACCREDITATION_TYPE == "rockwell";
export const ENABLE_DAYEND_FTP = window.ENABLE_DAYEND_FTP && ["robinson", "rockwell", "ayala", "bgc", "araneta", "pitx", "ortigas", "megaworld"].includes(LOCATION_ACCREDITATION_TYPE);
export const IS_ROBINSON_ACCREDITED = LOCATION_ACCREDITATION_TYPE == 'robinson' ? 1 : 0;

export const ENABLE_NEW_VAT_EXEMPT_SALES = window.ENABLE_NEW_VAT_EXEMPT_SALES;

export const PRODUCT_SKU_FIELDS = ['id', 'forcedMods', 'unforcedMods', 'product_name', 'price'];

export const IS_NON_VAT = window.IS_NON_VAT;

export const ENABLE_NO_TRANSACTION_MODAL = window.ENABLE_NO_TRANSACTION_MODAL;

export const USER_TYPE_IDS = {
    SUPER_ADMIN: 1,
    ENTERPRISE: 2,
    BRAND: 3,
    ADMIN: 4,
    MANAGER: 5,
    CASHIER: 6,
    WAITSTAFF: 7,
}

export const ENABLE_DISCOUNT_ON_PROMO_ITEMS = window.ENABLE_DISCOUNT_ON_PROMO_ITEMS;

export const QUEUE_STATUSES = {
    SYNCED: 1,
    UNSYNCED: 0
}

export const USE_SM_MARKETS_OR_FORMAT = window.USE_SM_MARKETS_OR_FORMAT;
export const USE_BFC_OR_FORMAT = window.USE_BFC_OR_FORMAT;

export const CAN_SEE_PAST_TRANSACTIONS = window.CAN_SEE_PAST_TRANSACTIONS;

export const ENABLE_TOGGLE_ONLINE_OFFLINE = window.ENABLE_TOGGLE_ONLINE_OFFLINE;

export const OFFLOAD = {
    sqliteOffloadUser: window.MosaicPosAppSupportedFeatures?.sqliteOffloadUser,
    // includes brands, channels, taxes, prices, categories, groups, products, open item and promos
    sqliteOffloadProduct: window.MosaicPosAppSupportedFeatures?.sqliteOffloadProduct,
    sqliteOffloadModifier: window.MosaicPosAppSupportedFeatures?.sqliteOffloadModifier,
    sqliteOffloadReceipt: window.MosaicPosAppSupportedFeatures?.sqliteOffloadReceipt && window.ENABLE_SQLITE_OFFLOAD_RECEIPT,
    sqliteOffloadReceiptDetail: window.MosaicPosAppSupportedFeatures?.sqliteOffloadReceiptDetail,
    sqliteOffloadPOSViewProductMenu: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSViewProductMenu,
    sqliteOffloadMA58: window.MosaicPosAppSupportedFeatures?.sqliteOffloadMA58,
    sqliteOffloadPrinter: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPrinter,
    sqliteOffloadMA68: window.MosaicPosAppSupportedFeatures?.sqliteOffloadMA68,
    sqliteOffloadMA33: window.MosaicPosAppSupportedFeatures?.sqliteOffloadMA33,
    sqliteOffloadPOSFE1310: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1310,
    sqliteOffloadPermission: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPermission,
    sqliteOffloadPOSFE1328: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1328,
    sqliteOffloadPosDate: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPosDate,
    useNetSalesWCharges: window.MosaicPosAppSupportedFeatures?.useNetSalesWCharges && USE_SM_MARKETS_OR_FORMAT,
    sqliteOffloadPOSFE1300: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1300,
    sqliteOffloadPOSFE1403: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1403,
    sqliteOffloadSplitBill: window.MosaicPosAppSupportedFeatures?.sqliteOffloadSplitBill,
    sqliteOffloadPOSFE1222: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1222,
    useGetReceipts: window.MosaicPosAppSupportedFeatures?.useGetReceipts,
    sqliteOffloadPOSFE1358: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1358,
    useOriginalGrossSales: window.MosaicPosAppSupportedFeatures?.useOriginalGrossSales && USE_SM_MARKETS_OR_FORMAT,
    sqliteOffloadPOSFE1427: window.MosaicPosAppSupportedFeatures?.sqliteOffloadPOSFE1427,
}
OFFLOAD.sqliteOffloadTableDetail = window.MosaicPosAppSupportedFeatures?.sqliteOffloadTableDetail && OFFLOAD.sqliteOffloadReceipt;
OFFLOAD.sqliteOffloadShiftTable = window.MosaicPosAppSupportedFeatures?.sqliteOffloadShiftTable && OFFLOAD.sqliteOffloadReceipt && !window.ENABLE_APP_BASED_MULTITERMINAL;

export const SQLITE_OFFLOAD_SYNCING_INTERVAL = 60; // in seconds
export const SQLITE_INTEGRATION_SYNCING_INTERVAL = 3;
export const SQLITE_OFFLOAD_MAX_RECEIPTS_COUNT = 5;
export const SQLITE_OFFLOAD_MAX_RECEIPT_CONTENTS_COUNT = 50;
export const SQLITE_OFFLOAD_FORCE_SYNC_CHUNK_SIZE = 5;
export const SQLITE_OFFLOAD_DELETE_ORDERS_OLDER_THAN = 15; // number of days
export const SQLITE_OFFLOAD_DELETE_RECEIPTS_OLDER_THAN = 30; // number of days
export const ENABLE_SQLITE_AUTO_BACK_UP_ON_DAY_END = OFFLOAD.sqliteOffloadReceipt && window.ENABLE_SQLITE_BACKUP_ON_DAY_END;

export const ENABLE_EPEI_REQUIRE_BEG_CASH_FUND = window.ENABLE_EPEI_REQUIRE_BEG_CASH_FUND;
export const ENABLE_SALES_CONSOLIDATOR = window.ENABLE_SALES_CONSOLIDATOR;
export const ENABLE_CASH_FUND_IMPROVEMENT = window.ENABLE_CASH_FUND_IMPROVEMENT;
export const ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE = window.ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE;
export const USE_RECEIPT_BASED = window.USE_RECEIPT_BASED;
export const HIDE_ITEM_DISCOUNT_BUTTON = window.HIDE_ITEM_DISCOUNT_BUTTON;
export const ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT = window.ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT;
export const IS_ACTIVE_LOCATION = window.IS_ACTIVE_LOCATION;

export const DOWNLINK_SPEED = 0.384;
export const ACTIVE_USER = 'activeUser';
export const ACTIVE_POS_DATE = 'activePosDate';
export const USE_NEW_AMOUNT_DUE_FORMULA = USE_SM_MARKETS_OR_FORMAT && window.FORCE_USE_NEW_AMOUNT_DUE_FORMULA;

// Alert messages
export const ALERT_MESSAGES = {
    UNSYNCED_TRANSACTIONS: "You currently have ___COUNT___ unsynchronized transactions. To proceed, kindly click the 'Force Sync' button.",
    NETWORK_UNSTABLE: "Your internet connection is currently unstable. Please check your network and try again.",
};

export const IMIN_PRINTER_FONT_SIZES = {
    SMALL: 18,
    MEDIUM: 20,
    LARGE: 32,
}

export const MOSAIC_COMPANY_NAME_AND_ADDRESS_FOR_PRINTOUT = window.MOSAIC_COMPANY_NAME_AND_ADDRESS_FOR_PRINTOUT;
export const BILLS_DENOMINATION = ['1000', '500', '200', '100', '50', '20', '10', '5', '1', '0.25', '0.10'];

export default {
    STATUSES,
    MAX_TRANSACTIONS,
    USE_EXPERIMENTAL_STORAGE_STRATEGY,
    PERMISSIONS,
    SYNC_STATUSES,
    POS_DATE_STATUSES,
    POST_BROADCAST_DELAY_SECONDS,
    CUSTOM_VISIBILITY_LIST,
    GET_BREAKDOWNS_FROM_DB,
    USE_DEXIE_STORAGE,
    DELETE_SETTLEMENT_ON_SYNC,
    MAX_PRODUCT_COUNT_FOR_ALL,
    ENABLE_LINE_ITEM_SEPARATORS,
    ENABLE_LINE_ITEM_SEPARATOR_EDITING,
    ENABLE_LINE_ITEM_REORDERING,
    ENABLE_OTHER_CHARGES_OFFLINE,
    ENABLE_IKONOMIKAL_INTEGRATION,
    ENABLE_BROADCAST_OPTIMIZATION,
    ENABLE_INVALID_LOCAL_SERIES_ALERT,
    ENABLE_PARENT_CHILD_CLASSIFICATION,
    ENABLE_HIDE_NO_SERVICE_CHARGE,
    ENABLE_UPDATED_GENERATED_BY,
    ENABLE_CASH_SALES_BREAKDOWN,
    ENABLE_UPDATED_REPORT_TIMESTAMP,
    ENABLE_FORBID_SHIFT_CHANGE_NO_CASH_INPUT,
    ENABLE_FORBID_DAYEND_NO_CASH_INPUT,
    SAVE_LINE_ITEM_SEPARATOR_POSITIONS,
    LINE_ITEM_SEPARATOR_CHAR_COUNT,
    OFFLINE_FORCE_EMPLOYEE_NO_SC,
    MAX_KOT_BEFORE_TRUNCATE_DISPLAY,
    PRODUCT_SKU_FIELDS,
    ENABLE_NO_TRANSACTION_MODAL,
    ENABLE_DISCOUNT_ON_PROMO_ITEMS,
    QUEUE_STATUSES,
    USE_SM_MARKETS_OR_FORMAT,
    USE_BFC_OR_FORMAT,
    CAN_SEE_PAST_TRANSACTIONS,
    ENABLE_EPEI_REQUIRE_BEG_CASH_FUND,
    ENABLE_SALES_CONSOLIDATOR,
    ENABLE_CASH_FUND_IMPROVEMENT,
    ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE,
    IMIN_PRINTER_FONT_SIZES,
    MOSAIC_COMPANY_NAME_AND_ADDRESS_FOR_PRINTOUT,
    ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
    IS_ACTIVE_LOCATION
};
