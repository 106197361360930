<template>
  <div class="row">
    <ul class="nav nav-tabs settings-tab" role="tablist">
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" :class="[activeMenu === 'day-end-settings' ? 'active' : '']"
          data-toggle="tab" href="#" @click="changeSettingsPage('day-end-settings')">
          <DayEndSetting />
          <div class="service-box-bottom">
            <h4>Day End</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link"
          :class="[activeMenu === 'shift-change-settings' ? 'active' : '']" data-toggle="tab" href="#"
          @click="changeSettingsPage('shift-change-settings')">
          <ShiftChange />
          <div class="service-box-bottom">
            <h4>Shift Change</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
          :class="[activeMenu === 'printer-settings' ? 'active' : '']" @click="changeSettingsPage('printer-settings')">
          <PrinterSetting />
          <div class="service-box-bottom">
            <h4>Printer Settings</h4>
          </div>
        </a>
      </li>
      
      <!-- <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link disabled" data-toggle="tab" href="#">
          <TableSettings />

          <div class="service-box-bottom">
            <h4>Tables</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link disabled" data-toggle="tab" href="#">
          <ReportsSection />

          <div class="service-box-bottom">
            <h4>Reports</h4>
          </div>
        </a>
      </li> -->
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
          :class="[activeMenu === 'bill-settings' ? 'active' : '']" @click="changeSettingsPage('bill-settings')">
          <BillsSettings />

          <div class="service-box-bottom">
            <h4>Bill</h4>
          </div>
        </a>
      </li>
      <!-- <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link disabled" data-toggle="tab" href="#"
          :class="[activeMenu === 'imin-settings' ? 'active' : '']" @click="changeSettingsPage('imin-settings')">
          <IminSettings />

          <div class="service-box-bottom">
            <h4>Imin Settings</h4>
          </div>
        </a>
      </li> -->
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
          :class="[activeMenu === 'cash-float' ? 'active' : '']" @click="changeSettingsPage('cashfloat-settings')">
          <FloatSettings />

          <div class="service-box-bottom">
            <h4>Cash Float</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6" v-if="isProductSettings">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
          :class="[['product-settings', 'product-settings-offload'].includes(activeMenu) ? 'active' : '']" @click="changeSettingsPage(productSettings)">
          <i class="fas fa-utensils productIcon"></i>

          <div class="service-box-bottom">
            <h4>Products</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6" v-if="enablePastTransactions">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
          :class="[activeMenu === 'past-transactions' ? 'active' : '']" @click="changeSettingsPage('past-transactions')">
          <PastTransactions />

          <div class="service-box-bottom">
            <h4>Past Transactions</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
          :class="[activeMenu === 'discounts-settings' ? 'active' : '']" @click="changeSettingsPage('discounts-settings')">
          <DiscountsSetting />

          <div class="service-box-bottom">
            <h4>Discounts</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6" v-if="enableTabSquare">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
           :class="[activeMenu === 'table-qr-settings' ? 'active' : '']" @click="changeSettingsPage('table-qr-settings')">
          <FloatSettings />

          <div class="service-box-bottom">
            <h4>Table QR</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
           :class="[activeMenu === 'reprint-settings' ? 'active' : '']" @click="changeSettingsPage('reprint-settings')">
          <ReprintSetting />

          <div class="service-box-bottom">
            <h4>Reprint</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6" v-if="ayalaSalesFileSettingsAvailable">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
           :class="[activeMenu === 'ayala-sales-file' ? 'active' : '']" @click="changeSettingsPage('ayala-sales-file')">
          <!-- This is close enough as a temporary icon -->
          <SalesFileSettingsIcon />

          <div class="service-box-bottom">
            <h4>Ayala Sales File</h4>
          </div>
        </a>
      </li>
      <li class="nav-item col-6">
        <a class="service-box settings-box my-2 nav-link" data-toggle="tab" href="#"
           :class="[activeMenu === 'app-info' ? 'active' : '']" @click="changeSettingsPage('app-info')">
          <!-- This is close enough as a temporary icon -->
          <PrinterSetting />

          <div class="service-box-bottom">
            <h4>App Info</h4>
          </div>
        </a>
      </li>
    </ul>
    <transfer-data-modal
      v-model="isTransferDataOpen"
      />
  </div>
</template>

<script>
import DayEndSetting from '@/spa/components/icons/dayEndSetting';
import ShiftChange from '@/spa/components/icons/shiftChange';
import PrinterSetting from '@/spa/components/icons/printerSetting';
import TableSettings from '@/spa/components/icons/tableSettings';
import ReportsSection from '@/spa/components/icons/reportsSection';
import BillsSettings from '@/spa/components/icons/billsSettings';
import DiscountsSetting from '@/spa/components/icons/discountsSetting';
import IminSettings from '@/spa/components/icons/iminSettings';
import SalesFileSettingsIcon from '@/spa/components/icons/salesFileSettings';
import PastTransactions from '@/spa/components/icons/pastTransactions.vue';
import TransferDataModal from '../modals/TransferDataModal.vue';
import FloatSettings from '../icons/floatSettings.vue';
import {
  ENABLE_PRODUCTS_SETTINGS,
  ENABLE_TAB_SQUARE,
  CAN_SEE_PAST_TRANSACTIONS,
  OFFLOAD
} from '@/spa/constants';
import { LocationBridge } from '../../../mobile_bridge/offload/offload';

export default {
  name: 'SettingsSidebar',

  components: {
    DayEndSetting,
    ShiftChange,
    PrinterSetting,
    TableSettings,
    ReportsSection,
    BillsSettings,
    IminSettings,
    TransferDataModal,
    FloatSettings,
    SalesFileSettingsIcon,
    PastTransactions,
    DiscountsSetting
},

  data() {
    return {
      activeMenu: null,
      isTransferDataOpen: false,
      isProductSettings: false,
      enablePastTransactions: CAN_SEE_PAST_TRANSACTIONS,
      enableTabSquare: ENABLE_TAB_SQUARE || false,
      runningInApp: window.MosaicPosAppVersionNumbers,
      // For testing only. Real check will be added as part of the SMB upload
      // ticket which completes this feature.
      ayalaSalesFileSettingsAvailable: window.TEST_AYALA_SALES_FILE_SETTINGS,
    };
  },

  computed: {
    isMultiTerminal() {
      const terminal = JSON.parse(sessionStorage.getItem('terminal'));
      return terminal?.is_shared_terminal;
    },

    productSettings() {
      const routeName = 'product-settings';

      if (OFFLOAD.sqliteOffloadPOSViewProductMenu) {
        return `${routeName}-offload`;
      }

      return routeName;
    }
  },

  async mounted() {
    this.activeMenu = this.$route.name;
    this.isProductSettings = ENABLE_PRODUCTS_SETTINGS;

    const locationBridge = new LocationBridge();
    const locations = await locationBridge.getAll();
    const location = locations[0];
    this.ayalaSalesFileSettingsAvailable = location.accreditation_type_id == 2; //using same for checking if location is ayala, do not change.
  },

  methods: {
    changeSettingsPage(url) {
      const ayalaBlockedRoutes = [
        'shift-change-settings',
        'product-settings',
        'product-settings-offload'
      ];

      if (this.ayalaSalesFileSettingsAvailable && ayalaBlockedRoutes.includes(url)) {
        Swal.fire({
          title: 'Information',
          text: 'This feature is not yet available for Ayala Malls. Please contact your administrator for more information.',
          icon: 'warning',
          confirmButtonText: 'Okay',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        }).then(() => {
          return;
        });
      } else {
        this.activeMenu = url;
        this.$router.push({ name: url });
      }
    },
    openTransferDataModal() {
      this.isTransferDataOpen = true;
    }
  },

  watch: {
  }
}
</script>
<style scoped>
  .productIcon {
    font-size: 70px;
    margin-top: 10px
  }

  .settings-box {
    border-radius: 10px;
    background-color: #fff;
  }

  .settings-box .service-box-bottom {
    margin-top: 8px
  }

  .settings-box .service-box-bottom h4 {
    font-size: 1rem;
  }

  @media screen and (min-width: 992px) {
    .settings-box .service-box-bottom {
      margin-top: 20px;
    }
  }
</style>
