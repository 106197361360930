<template>
  <div class="top-content">
    <m-alert-bar
      v-if="showReachedLimitBar"
      :variant="statuses.limitReached.variant"
      :icon="statuses.limitReached.icon"
    >
      {{ statuses.limitReached.message }}
    </m-alert-bar>

    <app-update-alert />

    <m-alert-bar
      v-if="showAllSyncedBar"
      :variant="statuses.allDataSycned.variant"
      :icon="statuses.allDataSycned.icon"
      :dismissible="true"
      @click="showAllSyncedBar = false"
      @close="showAllSyncedBar = false"
      class="cursor-pointer"
    >
      {{ statuses.allDataSycned.message }}
    </m-alert-bar>

    <m-alert-bar
      v-for="notification in latestDeliveryNotifications"
      :variant="notification.type.toLowerCase()"
      :icon="statuses.newOrder.icon"
      :dismissible="true"
      @click="gotoDeliveryPage(notification)"
      @close="removeLatestDeliveryNotifications(notification.id)"
      class="cursor-pointer"
      :class="notification.type.toLowerCase()+'-bg'"
    >
      {{ statuses.newOrder.message }}
      <span :class="notification.type">{{ `${notification.type == 'Tabsquare' ? notification.table_no : notification.type }` }}</span>
    </m-alert-bar>

    <m-alert-bar
      v-for="notification in latestCancelledDeliveryNotifications"
      variant="warning"
      :icon="statuses.cancelledOrder.icon"
      :dismissible="true"
      @click="gotoDeliveryPageWithCancelledFilter(notification)"
      @close="removeLatestCancelledDeliveryNotifications(notification.id)"
      class="cursor-pointer"
    >
      {{ notification.type }}
      {{ statuses.cancelledOrder.message }}
    </m-alert-bar>

    <m-alert-bar
      v-for="(notification, index) in latestOnlinePaymentNotifications"
      :key="`notification-${index}`"
      variant="success"
      :icon="notification.type === 'paymongo' ? null : statuses.onlinePayment.icon"
      :dismissible="true"
      @click="gotoOrderPage(notification)"
      @close="removeLatestOnlinePaymentNotifications(notification.id)"
      class="cursor-pointer"
    >
      {{ getNotificationMessage(notification) }}
    </m-alert-bar>

    <m-alert-bar
      v-if="!isActiveLocation"
      variant="warning"
      icon="warning"
      :dismissible="true"
      class="cursor-pointer"
      @close="handleCloseInactiveLocation"
    >
      This location is inactive. Please contact Mosaic Support.
    </m-alert-bar>
  </div>
</template>

<script>
import bus from '@/spa/utils/bus';
import MAlertBar from "@/spa/components/common/MAlertBar";
import AppUpdateAlert from "@/spa/components/common/AppUpdateAlert";
import { mapState, mapMutations } from "vuex";
import {OFFLOAD, SYNC_STATUSES} from "@/spa/constants";
import { getSyncedOrders } from '@/spa/services/sync-service';

export default {
  name: "TopContent",

  components: { MAlertBar, AppUpdateAlert },

  data() {
    return {
      statuses: {
        limitReached: {
          message:
            "Maximum unsynced transactions reached. Please connect online to sync your data to the Back Office.",
          icon: "fas fa-exclamation-triangle",
          variant: "warning",
        },

        allDataSycned: {
          message: "Your data is synced to the cloud",
          icon: "fa fa-check-circle",
          variant: "success",
        },

        newOrder: {
          message: "New order from ",
          icon: "fas fa-truck",
        },

        cancelledOrder: {
          message: " order has been cancelled",
          icon: "fas fa-truck",
        },

        onlinePayment: {
          icon: "fas fa-qrcode",
        },
      },

      showAllSyncedBar: false,
      isActiveLocation: !!window.IS_ACTIVE_LOCATION,
    };
  },

  computed: {
    ...mapState("global", [
      "syncStatus",
      "latestDeliveryNotifications",
      "latestCancelledDeliveryNotifications",
      "latestOnlinePaymentNotifications",
    ]),

    ...mapState(["latestDeliveryOrders", "orders"]),

    ...mapState('settings', ['posDate']),

    showReachedLimitBar() {
      return this.syncStatus === SYNC_STATUSES.FULL;
    },
  },

  methods: {
    ...mapMutations("global", [
      "removeLatestDeliveryNotifications",
      "removeLatestCancelledDeliveryNotifications",
      "removeLatestOnlinePaymentNotifications",
      "setPaymentQRDetails",
      "setShowPaymentQRModal",
    ]),

    gotoDeliveryPage(notification) {
      this.removeLatestDeliveryNotifications(notification.id);
      const isTabSquare = notification.type === "Tabsquare";
      const currentRoute = this.$router.currentRoute.value;

      const routeConfig = isTabSquare
        ? { name: 'tables', query: { order_id: notification.id }, params: { serviceType: notification.service_type, serviceAreaId: notification.service_type_id } }
        : {
          name: "delivery",
          params: {
            channelName: notification.type,
            lastDeliveryOrderId: notification.id,
          },
        };

      if (isTabSquare && currentRoute.name === "tables" && notification?.service_type === currentRoute.params?.serviceType) {
        bus.emit("setViewOrder", notification.id);
        return;
      }

      this.$router.push(routeConfig);
    },

    gotoDeliveryPageWithCancelledFilter(notification) {
      this.removeLatestCancelledDeliveryNotifications(notification.id);

      this.$router.push({
        name: "delivery",
        params: {
          channelName: notification.type,
          lastDeliveryOrderId: notification.id,
          shortOrderId: notification.shortOrderId,
        },
      });
    },

    async gotoOrderPage(notification) {
      console.log(`TopContent - gotoOrderPage is called. (Triggered when user clicks the top notification when a successful online payment is received.)`);

      this.removeLatestOnlinePaymentNotifications(notification.id);

      if (notification.type === 'tabsquare') {
        this.$router.push({
          name: "bill-settings"
        });

        return;
      }

      let settled = false;
      let order = this.orders.find(
        (order) => order._id === notification.orderId
      );

      if(!order) {
          const syncedOrders = await getSyncedOrders(this.posDate);
          order = syncedOrders.find((order) => order._id === notification.orderId)
          settled = true;
      }

      // No need to show QRCode modal for paymongo online payment
      if (notification.type !== "paymongo") {
        const params = {
          paymentProvider: notification?.type,
          orderId: notification.orderId,
          bill: order?.bill_num ?? null,
          table: order?.tableId ?? null,
          payment: notification.paymentChannel,
          amount: notification.amount,
          status: notification.status,
          invoiceUrl: notification.invoiceUrl,
          externalId: notification.externalId,
        };

        this.setPaymentQRDetails(params);
        this.setShowPaymentQRModal(true);
      }

      if (!notification.isFullyPaid && !settled) {
        this.$router.push({
          name: "payments",
          params: {
            orderId: notification.orderId,
            tableName: order?.tableName,
          },
        });
      }
    },

    getNotificationMessage(notification) {
      console.log(`TopContent - getNotificationMessage is called`);
      console.log(`notification: `, notification);

      const notifications = {
        default: `Online payment of ₱ ${this.$filters.formatPrice(notification?.amount)} has
          been made. Ref# ${notification?.externalId}`,
        xendit: `Online payment of ₱ ${this.$filters.formatPrice(notification?.amount)} has
          been made. Ref# ${notification?.externalId}`,
        paymongo: `Payment for Bill No. ${notification?.billNum} has been successfully received. Ref No. ${notification?.externalId}`,
        tabsquare: `Online payment has been made from ${notification?.table_no}`
      };

      return notifications[notification?.type || 'default'];
    },

    handleCloseInactiveLocation() {
      this.isActiveLocation = true;
    },
  },

  watch: {
    syncStatus(newValue) {
      // Disable this check to avoid confusion
      if (OFFLOAD.sqliteOffloadReceipt) {
        return false; // Exit early if SQLite offload receipt is present
      }

      if (newValue === SYNC_STATUSES.SYNCED) {
        this.showAllSyncedBar = true;
      } else {
        this.showAllSyncedBar = false;
      }
    },
  },
};
</script>

<style scoped>
.top-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.alipaydstore-bg {
  background: #1677FF !important;
}

.foodpanda {
  text-transform: lowercase;
}
</style>
